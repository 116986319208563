const SDK_VERSION = process.env.PRIMER_SDK_VERSION as string;

export const getPrimerHeaders = (
  accessToken: string,
  checkoutSessionId: string,
  apiVersion?: string,
) => ({
  'primer-client-token': accessToken,
  'primer-sdk-client': 'WEB',
  'primer-sdk-version': SDK_VERSION,
  'primer-sdk-checkout-session-id': checkoutSessionId,
  ...(apiVersion && { 'x-api-version': apiVersion }),
});
