/* eslint-disable no-cond-assign */
/* eslint-disable no-bitwise */
/* eslint-disable no-plusplus */

import { Base64 } from 'js-base64';

// TODO: replace with atob/btoa and remove this file
export const base64 = { encode, decode: Base64.decode };

interface EncodeOptions {
  urlSafe?: boolean;
}

// encoder
// [https://gist.github.com/999166] by [https://github.com/nignag]
function encode(input: string, options: EncodeOptions = {}): string {
  const { urlSafe = false } = options;
  return Base64.encode(input, urlSafe);
}
