import { base64 } from '../utils/base64';
import type { DecodedClientToken } from './ClientTokenHandler';

// TODO: validate token signature
export function decodeClientToken(clientToken: string): DecodedClientToken {
  const token = parseClientToken(clientToken);

  // expiration inside token is missing milliseconds
  const expirationTimestamp = token.exp * 1000;

  if (Date.now() > expirationTimestamp)
    throw new Error(
      `${expired}. Expiration date: ${new Date(
        expirationTimestamp,
      ).toISOString()}`,
    );

  return token;
}

const message = 'The provided `clientToken`';
const expired = `${message} has expired`;
const malformed = `${message} is malformed`;

function parseClientToken(clientToken: string): DecodedClientToken {
  try {
    const [header, payload] = clientToken.split('.');
    const encoded = payload || header;

    return JSON.parse(base64.decode(encoded));
  } catch {
    throw new Error(malformed);
  }
}
